<script setup lang="ts">

</script>

<template>
<footer class="topnav-footer"/>
</template>

<style>
.topnav-footer {
  margin-top: var(--size-fluid-3);
}
</style>