<script setup lang="ts">
const nativeComponent = ['img', 'div'];

const isBurgerMenuOpen = ref(false);

function resolveComponentOrNative(component: string) {
  if (nativeComponent.includes(component)) return component

  return resolveComponent(component)
}

const props = withDefaults(
    defineProps<{
      topnav?: boolean
      toolbar?: boolean
      circularMenu?: boolean
    }>(),
    {
      topnav: true,
      toolbar: true,
      circularMenu: false,
    },
)

const route = useRoute();
const config = useAppConfig().layout;

const toolbarEnabled = computed(() => {
  return config?.toolbar?.enabled !== false && props.toolbar !== false;
})
</script>

<template>
  <UITopnavHeader>
    <template #logo>
      <NuxtLink class="topnav-logo-container">
        <component
            :is="
                  resolveComponentOrNative(
                    config?.navigation.logo.component,
                  )
                "
            v-bind="config?.navigation.logo.props"
        />
      </NuxtLink>
      <h4
          v-if="config?.showTitle"
          class="topnav-page-title"
      >
        <slot name="title">{{ route.meta.title }}</slot>
      </h4>
      <component
          :is="
                resolveComponentOrNative(
                  config?.navigation?.header?.component,
                )
              "
          v-if="config?.navigation?.header?.component"
          v-bind="config?.navigation?.header?.props"
      />
    </template>
    <template v-if="toolbarEnabled" #toolbar class="topnav-header-right">
      <div class="topnav-toolbar-wrapper">
        <component
            :is="resolveComponentOrNative(config?.toolbar?.component)"
            v-if="config?.toolbar?.component"
            v-bind="config?.toolbar?.props"
        />
      </div>
    </template>
  </UITopnavHeader>

  <main class="topnav-main-container content-wrapper">
    <NuxtPage/>
  </main>

  <UITopnavFooter/>
</template>

<style>
.topnav-main-container {
  min-height: 100vh;
}

.topnav-metadata-container {
  display: flex;
  height: var(--tw-size-16);
  width: 100%;
  align-items: center;
  column-gap: var(--tw-size-4);
}

.topnav-logo-container {
  display: flex;
  place-content: center;
}

.topnav-page-title {
  color: var(--tw-color-slate-800);
  font-size: var(--tw-font-size-lg);
  line-height: var(--tw-line-height-7);
  font-weight: bold;
}

.topnav-toolbar-wrapper {
  margin-left: auto;
}
</style>