import type {RouteLocationRaw} from 'vue-router'

export interface TairoTopnavResolvedConfig {
    name: string
    divider?: boolean
    icon: {
        name: string
        class?: string
    }
    children?: any[]
    component?: {
        name: string
        props?: any
    }
    to?: RouteLocationRaw
    click?: () => void | Promise<void>
    activePath?: string
}

export function useLayout() {
    const route = useRoute()
    const app = useAppConfig()

    const menuItems = computed(() => {
        if (
            app.layout?.navigation?.enabled === false ||
            app.layout?.navigation?.items?.length === 0
        ) {
            return []
        }
        return app.layout?.navigation?.items?.map(
            (navigation) =>
                <TairoTopnavResolvedConfig>{
                    ...navigation,
                },
        )
    })

    const activeMenuItem = computed(() => {
        return menuItems.value?.find((item) => {
            if (item.activePath) {
                return route.path.startsWith(item.activePath)
            }
            if (item.to) {
                return route.path.startsWith(item.to.toString())
            }
            return false
        })
    })
    const selectedMenuItem = useState(
        'topnav-selected-menu-item',
        () => activeMenuItem.value,
    )
    watch(activeMenuItem, (item) => {
        selectedMenuItem.value = item
    })

    return {
        menuItems,
        activeMenuItem,
        selectedMenuItem,
    }
}
