<script setup lang="ts">
import {TooltipProvider} from "radix-vue";
import {Toaster} from "vue-sonner";
import {Client, provideClient, cacheExchange, fetchExchange} from '@urql/vue';
import {authExchange} from "@urql/exchange-auth";

const apiURL = useNuxtApp().$platformaticApiUrl;
const {token} = useAuth();
useColorMode();


const client = new Client({
  url: `${apiURL}/graphql`,
  exchanges: [
    cacheExchange,
    authExchange(async utils => {
      return {
        addAuthToOperation(operation) {
          if (!token.value) {
            return operation;
          }

          return utils.appendHeaders(operation, {
            Authorization: token.value,
          })
        },
        async refreshAuth() {
          await navigateTo('/auth/login');
        },
      }
    }),
    fetchExchange
  ],
  requestPolicy: 'cache-and-network'
});

provideClient(client);
</script>

<template>
  <ClientOnly>
    <UIPageLoader/>
    <TooltipProvider>
      <UITopnavLayout/>
    </TooltipProvider>
    <Toaster rich-colors/>
  </ClientOnly>
</template>